import React from 'react'
import './footer.css'
import logo  from '../../Assets/LOGICBUNNY.jpg'
import instagram_icon  from '../../Assets/instagram_icon.png'
import pintester_icon  from '../../Assets/pintester_icon.png'
import whatsapp_icon  from '../../Assets/whatsapp_icon.png'
const Footer = () => {
  return (
    <div className='footer-container'>
<div className="logo">
    <img src={logo} alt="" width="70px" height="70px" />
</div >

<div className="socialmedia-container">

<div className="social-icon">
    <img src={instagram_icon} alt="" />
</div>
<div className="social-icon">
    <img src={pintester_icon} alt="" />
</div>
<div className="social-icon">
    <img src={whatsapp_icon} alt="" />
</div>

</div>
<hr/>

<div className="copyright">
<p>&copy; 2020 - All Rights Reserved. Designed by Prakriya Technology </p>
</div>



    </div>
  )
}

export default Footer