import React from 'react'

import Home from './Home'

const Shop = () => {
  return (
    <div>
        <Home/>
        
        </div>

  )
}

export default Shop