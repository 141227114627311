import React from 'react';
import './AboutUs.css';

const AboutUs = () => {
  return (
    <div className="about-us">
      <section className="intro">
        <h1>About LogicBunny</h1>
        <p>
          Founded in July 2020, LogicBunny Technology Private Ltd is a dynamic and innovative technology company based in Bangalore, Karnataka. We specialize in developing cutting-edge Rummy games and comprehensive software solutions, committed to delivering excellence and innovation.
        </p>
      </section>

      <section className="mission">
        <h2>Our Mission</h2>
        <p>
          At LogicBunny, our mission is to push the boundaries of technology to create impactful and engaging solutions. We strive to offer superior Rummy gaming experiences and tailor-made software solutions that meet the unique needs of our clients and set new industry standards.
        </p>
      </section>

      <section className="expertise">
        <h2>Our Expertise</h2>
        <div className="expertise-grid">
          <div className="expertise-item">
            <h3>Rummy Game Development</h3>
            <p>
              We craft immersive Rummy games using advanced algorithms and real-time technology to ensure smooth and fair gameplay. Our games are designed to be engaging and adaptable to various platforms.
            </p>
          </div>
          <div className="expertise-item">
            <h3>Custom Software Solutions</h3>
            <p>
              From custom software development to cloud-based solutions, we provide a wide range of services to help businesses achieve their goals. Our solutions are designed to be scalable and tailored to your specific requirements.
            </p>
          </div>
          <div className="expertise-item">
            <h3>Innovation & Technology</h3>
            <p>
              Our team stays ahead of the curve by continuously exploring new technologies and methodologies. We are committed to integrating the latest innovations to deliver cutting-edge solutions.
            </p>
          </div>
        </div>
      </section>

      <section className="why-choose-us">
        <h2>Why Choose Us?</h2>
        <ul>
          <li>Expertise in creating high-quality Rummy games with robust anti-cheating mechanisms.</li>
          <li>Custom software solutions tailored to your business needs.</li>
          <li>Commitment to continuous innovation and staying ahead of industry trends.</li>
          <li>Dedicated team of professionals with a passion for technology and excellence.</li>
        </ul>
      </section>

      <section className="join-us">
        <h2>Join Us</h2>
        <p>
          We invite you to be a part of our journey to revolutionize the tech industry. Whether you are a prospective client, partner, or future team member, let’s collaborate to make a difference and achieve great things together.
        </p>
      </section>
    </div>
  );
}

export default AboutUs;
