import React from 'react';
import './Portfolio.css'; // Make sure to create this CSS file for styling

const Portfolio = () => {
  return (
    <div className="portfolio-container">
      <div className="portfolio-item">
        <h2>Rummy Game</h2>
        <p><strong>Overview:</strong> A real-time multiplayer Rummy game that offers a seamless and immersive experience for players. Designed to bring players together, this game features an intuitive interface and responsive controls, ensuring a smooth and enjoyable gaming experience. Advanced algorithms are employed to guarantee fair play and to handle various Rummy formats, including classic and modern variants. The game is optimized for high performance, supporting multiple simultaneous users without compromising on speed or stability.</p>
        <ul>
          <li>Real-time gameplay with smooth animations and responsive controls that provide a dynamic and engaging user experience.</li>
          <li>Advanced anti-cheating mechanisms to uphold game integrity and provide a fair environment for all players.</li>
          <li>Cross-platform compatibility allowing users to play seamlessly across web and mobile devices, enhancing accessibility and user convenience.</li>
        </ul>
      </div>

      <div className="portfolio-item">
        <h2>Ludo Game</h2>
        <p><strong>Overview:</strong> A modern take on the classic board game Ludo, designed for digital platforms to deliver a rich and interactive gaming experience. The game combines traditional gameplay with innovative features, offering multiplayer modes where friends and family can join in. It includes engaging visuals, smooth animations, and various customization options to enhance the player experience. AI-based opponents are integrated to provide a challenging single-player mode, making the game enjoyable for all users.</p>
        <ul>
          <li>Multiplayer mode supporting up to 4 players, facilitating both competitive and cooperative gameplay among friends and family.</li>
          <li>Chat functionality to improve the social experience by allowing players to communicate during the game.</li>
          <li>AI-based opponents for single-player mode, providing a challenging experience even when playing alone.</li>
        </ul>
      </div>

      <div className="portfolio-item">
        <h2>Poker Game</h2>
        <p><strong>Overview:</strong> Experience the thrill of online poker with our immersive, feature-rich platform that combines strategy, skill, and excitement. Whether you’re a beginner or a seasoned player, our poker game offers endless entertainment and competitive gameplay.</p>
        <ul>
        Our poker game offers a variety of modes and popular variants to cater to all types of players. You can enjoy No Limit Texas Hold’em, the most popular poker variant worldwide, where players aim to form the best hand using two hole cards and five community cards. For those looking for more excitement, we offer Pot Limit Omaha, known for its fast-paced action and complex strategies. Additionally, our platform features Sit & Go and Tournament Modes for competitive play, as well as casual Cash Games for players seeking quick rounds. 
        Each variant comes with multiple table options and customizable betting limits, ensuring there’s something for everyone.
        </ul>
      </div>

      <div className="portfolio-item">
        <h2>e-KYC Solution</h2>
        <p><strong>Overview:</strong> Developed a sophisticated web-based KYC (Know Your Customer) service platform aimed at streamlining the customer verification process for financial institutions. The platform was designed to handle large volumes of verification requests efficiently while maintaining high security standards. It features a user-friendly interface and integrates with various databases and verification services to ensure quick and accurate processing of customer information.</p>
      </div>
    </div>
  );
};

export default Portfolio;
